import React from "react";
import get from 'lodash/get'
import Layout from '../components/layout'
import FirebaseContactForm from '../components/FirebaseContactForm'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import "../styles/contact-form.css"

class ContactPage extends React.Component {
  render() {
    const siteTitle = get(
      this,
      'props.data.cosmicjsSettings.metadata.site_title'
    )
    const location = get(this, 'props.location')
    return (
      <Layout location={location}>
        <SEO title="Lean Softare Delivery Blog" />
        <Helmet title={siteTitle} />
              <div className="wrapper">
                <h3 className="title">
                  Contact Author
                </h3>
                <div className="contact-form">
                  <FirebaseContactForm />
                </div>
              </div>
      </Layout>
    );
  };
}

export default ContactPage;

export const pageQuery = graphql`
  query IndexQuery {
    cosmicjsSettings(slug: { eq: "general" }) {
      metadata {
        site_title
        author_name
        author_bio
        author_avatar {
          imgix_url
        }
      }
    }
  }
`